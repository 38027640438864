<template>
  <div class="card">
    <div class="w-full flex justify-between">
      <Button
        label="Atras"
        icon="pi pi-arrow-left"
        class="p-button-secondary p-mr-2 p-mb-2"
        @click="$router.go(-1)"
        size="small"
      />
      <Button
        label="Crear Salida"
        icon="pi pi-plus"
        class="p-button-success p-mr-2 p-mb-2"
        @click="onValidarData"
        size="small"
      />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="cl-1 flex flex-col gap-2">
        <label for="Motivo">Motivo</label>
        <Dropdown v-model="dataSalida.Motivo"
                  :options="motivos"
                  optionLabel="Name"
                  optionValue="Code"
                  filter
                  placeholder="Seleccione un motivo"
                  class="w-full md:w-14rem p-component p-inputtext-xs"
                  size="small"
        />
      </div>
      <div class="cl-2 flex flex-col gap-2">
        <label for="Motivo">Bodega</label>
        <Dropdown
          v-model="dataSalida.WhsCode"
          :options="bodegas"
          optionLabel="Label"
          optionValue="WhsCode"
          filter
          placeholder="Seleccione una bodega"
          class="w-full md:w-14rem p-component p-inputtext-xs"
          size="small"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 my-4 gap-4">
      <div class="cl-1 flex flex-col gap-2">
        <label for="Comentarios">Comentarios</label>
        <Textarea  v-model="dataSalida.Comments" class="w-full" rows="2" cols="30"/>
      </div>
      <div class="cl-1 flex flex-col gap-2">
        <label for="Comentarios">Articulos/Lotes</label>
        <AutoComplete
          v-model="selectedArticulo"
          optionLabel="Label"
          optionValue="KeyValue"
          class="p-component p-inputtext-xs w-full"
          inputClass="w-full text-xs"
          :suggestions="filteredArticulo"
          @complete="search"
          @item-select="onArticuloSeleccionado"
          :forceSelection="true"
          placeholder="Buscar articulo (Nombre, Codigo de barras, Lote)"
        >
          <template #option="{option}">
            <div class="w-full">
              <div class="overflow-hidden relative shadow-lg  flex items-center gap-6">
                <img  style="left: -1.5rem" class="absolute  w-32 h-32 rounded-full shadow-lg" :src="`https://int.pharmasan.net/api/config-ov/articulos/get-image/${option.ItemCode}.jpg`">
                <div class="flex flex-col py-2.5" style="padding-left: 9rem">
                  <strong class="text-xxs text-blue-600">{{ option.ItemName }}</strong>
                  <div class="grid grid-cols-2 text-xxs">
                    <span> <strong class="mr-2">Id:</strong>{{option.Id}}</span>
                    <span> <strong class="mr-2">Mx:</strong>{{option.ItemCode}}</span>
                  </div>
                  <p class="text-xxs"> <strong class="mr-2">Cod barras:</strong>{{option.CodeBars}}</p>
                  <div class="grid grid-cols-2 text-xxs">
                    <span> <strong class="mr-2">Stock:</strong>{{option.CantStock}}</span>
                    <span> <strong class="mr-2">Lote:</strong>{{option.NumLote}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </AutoComplete>
      </div>
    </div>
    <div class="tabla">
      <DataTable :value="dataSalida.DocumentLines" tableStyle="font-size:0.75rem" size="small">
        <template #empty>
          <div class="flex flex-col items-center justify-center">
            <p class="text-sm">No hay articulos agregados</p>
          </div>
        </template>
        <Column header="Cod. Articulo">
          <template #body="{data}">
            <div class="flex">
              <span class="pi pi-trash text-theme-6 cursor-pointer mr-2" @click="onRemoveKeyValue(data.KeyValue)"/>
              <span class="text-blue-600">{{ data.ItemCode }}</span>
            </div>
          </template>
        </Column>
        <Column header="Articulo Descripción">
          <template #body="{data}">
            <div class="flex">
              {{ data.ItemName }}
            </div>
          </template>
        </Column>
        <Column header="Lote">
          <template #body="{data}">
            <div class="flex">
              {{ data.BatchNumber }}
            </div>
          </template>
        </Column>
        <Column header="Cantidad Bodega">
          <template #body="{data}">
            <div class="flex">
              {{ data.StockQuantity }}
            </div>
          </template>
        </Column>
        <Column header="Cantidad Salida">
          <template #body="{data}">
            <div class="flex">
              <InputText v-model="data.Quantity"/>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog v-model:visible="errorDialog" modal header=" Listado de Errores " :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <ul class="list-decimal">
        <li v-for=" (message, index) in listErrors" :key="index" class="text-red-600">
          {{index+1}}&nbsp;{{ message }}
        </li>
      </ul>
    </Dialog>
  </div>
  <ConfirmPopup/>
  <Toast/>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import * as yup from 'yup'
// import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import MotivosInventarioService from './services/motivosInventario'
import BodegasInventarioService from './services/bodegasInventario'
import ArticulosSalidasInventario from './services/ArticulosSalidasInventario'
import SalidasInventarioService from './services/SalidasInventario'
import Swal from 'sweetalert2'
// const confirm = useConfirm()
const toast = useToast()
const router = useRouter()
const _MotivosInventarioService = new MotivosInventarioService()
const _BodegasInventarioService = new BodegasInventarioService()
const _ArticulosSalidasInventario = new ArticulosSalidasInventario()
const _SalidasInventarioService = new SalidasInventarioService()
const motivos = ref([])
const bodegas = ref([])
const dataSalida = ref({
  Motivo: '',
  WhsCode: '',
  Comments: null,
  DocumentLines: []
})
const selectedArticulo = ref(null)
const filteredArticulo = ref([])
const listErrors = ref([])
const errorDialog = ref(false)
onMounted(() => {
  _MotivosInventarioService.getMotivosAjuste().then(({ data }) => {
    motivos.value = data
  })
  _BodegasInventarioService.listarBodegasTodas({ tipoBodega: 4 }).then(({ data }) => {
    bodegas.value = data
  })
})
const search = (event) => {
  const _search = event.query
  if (_search.length < 4) {
    filteredArticulo.value = []
  } else {
    if (!dataSalida.value.WhsCode) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe seleccionar una bodega', life: 3000 })
    _ArticulosSalidasInventario.search({
      WhsCode: dataSalida.value.WhsCode,
      Articulo: _search
    }).then(({ data }) => {
      filteredArticulo.value = data
    })
  }
}
const onArticuloSeleccionado = ({ value }) => {
  if (dataSalida.value.DocumentLines.some(x => x.KeyValue === value.KeyValue)) {
    selectedArticulo.value = null
    return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El articulo ya se encuentra agregado', life: 3000 })
  }
  dataSalida.value.DocumentLines.push({
    ItemCode: value.ItemCode,
    ItemName: value.ItemName,
    ArticuloId: value.ArticuloId,
    Quantity: value.CantStock,
    StockQuantity: value.CantStock,
    BatchNumber: value.NumLote,
    WhsCode: dataSalida.value.WhsCode,
    KeyValue: value.KeyValue
  })
  selectedArticulo.value = null
}
const onRemoveKeyValue = (KeyValue) => {
  const index = dataSalida.value.DocumentLines.findIndex((item) => item.KeyValue === KeyValue)
  dataSalida.value.DocumentLines.splice(index, 1)
}
const onValidarData = async () => {
  try {
    const schema = yup.object().shape({
      Motivo: yup.string().required().label('Motivo'),
      WhsCode: yup.string().required().label('Bodega'),
      Comments: yup.string().nullable().label('Comentarios'),
      DocumentLines: yup.array().of(
        yup.object().shape({
          ItemCode: yup.string().required().label('Codigo de articulo'),
          ItemName: yup.string().required().label('Nombre de articulo'),
          ArticuloId: yup.number().required().label('Id de articulo'),
          Quantity: yup
            .number()
            .required()
            .max(yup.ref('StockQuantity'), 'La cantidad no puede ser mayor que la cantidad en stock')
            .test('quantity-validation', 'La cantidad debe ser menor o igual a la cantidad en stock', function (value) {
                const stockQuantity = this.resolve(yup.ref('StockQuantity'))
                return value <= stockQuantity
              })
            .label('Cantidad de salida'),
          StockQuantity: yup.number().required().label('Cantidad de stock'),
          BatchNumber: yup.string().required().label('Lote'),
          WhsCode: yup.string().required().label('Bodega')
        })
      )
    })
    const mydata = await schema.validate(dataSalida.value, { abortEarly: false })
    listErrors.value = []
    errorDialog.value = false
    confirm1(mydata)
  } catch (error) {
    errorDialog.value = true
    listErrors.value = error.errors.map(cadena => {
      const partes = cadena.split('.')
      return partes[0]
    })
  }
}
const confirm1 = (data) => {
   Swal.fire({
    title: 'Advertencia',
    text: '¿Esta seguro de crear la salida?',
    icon: 'warning',
    showCancelButton: true
  }).then((result) => {
    if (result.isConfirmed) {
      createSalida(data)
    }
  })
}
const clearData = () => {
  dataSalida.value = {
    Motivo: '',
    WhsCode: '',
    Comments: null,
    DocumentLines: []
  }
}
const createSalida = (datos) => {
  _SalidasInventarioService.crear(datos).then(() => {
    Swal.fire({
      title: 'Salida creada',
      text: 'La salida se ha creado correctamente',
      icon: 'success'
    })
    clearData()
    setTimeout(() => {
      router.push({ name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas' })
    }, 3000)
  })
}
</script>
