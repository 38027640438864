import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA
export default class MotivosInventarioService {
  getMotivosAjuste () {
    return http.get(`${baseUrl}/concepto-entrada/search`, {
      headers: {
        loading: true
      }
    })
  }
}
