import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA
export default class BodegasInventarioService {
  listarBodegasTodas (params) {
    return http.get(`${baseUrl}/bodegas/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
