import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA
export default class ArticulosSalidasInventario {
  search (params) {
    return http.get(`${baseUrl}/articulos/search`, {
      headers: {
        loading: true
      },
      params
    })
  }
}
