import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA
export default class SalidasInventarioService {
  crear (data) {
    return http.post(`${baseUrl}/salidas`, data, {
      headers: {
        loading: true
      }
    })
  }
}
